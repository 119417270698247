import { useEffect, useState } from "react";

import { AppBar } from "./DrawerStyles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import DesktopAppToolbar from "components/AppToolbar/DesktopAppToolbar";
import DesktopDrawer from "./DesktopDrawer";
import MobileAppToolbar from "components/AppToolbar/MobileAppToolbar";
import MobileDrawer from "./MobileDrawer";
import colors from "styles/colors";
import useScreenSize from "hooks/useScreenSize/useScreenSize";

const MiniDrawer = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(true);
  const { isDesktop } = useScreenSize();

  useEffect(() => {
    setOpen(isDesktop);
  }, [isDesktop]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        sx={{
          background:
            process.env.REACT_APP_ENVIRONMENT === "production"
              ? null
              : process.env.REACT_APP_ENVIRONMENT === "development"
              ? colors.successContrast
              : null,
        }}
        position="fixed"
        open={open}
        islargescreen={String(isDesktop)}
      >
        {isDesktop ? (
          <DesktopAppToolbar open={open} handleDrawerOpen={handleDrawerOpen} />
        ) : (
          <MobileAppToolbar open={open} handleDrawerOpen={handleDrawerOpen} />
        )}
      </AppBar>

      {isDesktop ? (
        <DesktopDrawer open={open} setOpen={setOpen} />
      ) : (
        <MobileDrawer open={open} setOpen={setOpen} />
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: isDesktop ? 3 : 0,
          mt: 8,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MiniDrawer;
