import { Dialog, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useDialog } from "context/DialogContext/DialogContext";

const GenericDialog = () => {
  const { isOpen, closeDialog, dialogContent, dialogProps } = useDialog();

  return (
    <Dialog open={isOpen} onClose={closeDialog} {...dialogProps}>
      {dialogProps.fullScreen && (
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {dialogContent}
    </Dialog>
  );
};

export default GenericDialog;
