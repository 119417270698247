import { gql } from "@apollo/client";

export const ALL_RFQS = gql`
  query rfqs($filter: RfqFilter) {
    rfqsSummary(filter: $filter) {
      id
      label
      itemsCount
      vendors {
        contactEmail
        contactName
        hidden
        id
        name
      }
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const RFQS_BY_STATUS = gql`
  query rfqsByStatus($filter: RfqFilter) {
    rfqsSummary(filter: $filter) {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      label
      status
      vendors {
        id
        hidden
      }
    }
  }
`;

export const RFQ_VENDORS = gql`
  query rfqsVendors($filter: RfqFilter) {
    rfqsSummary(filter: $filter) {
      id
      vendors {
        id
        hidden
      }
    }
  }
`;

export const GET_RFQ = gql`
  query rfq($id: ID!) {
    rfq(id: $id) {
      id
      createdAt
      createdBy
      updatedAt
      updatedBy
      label
      status
      note
      vendors {
        contactEmail
        contactName
        hidden
        id
        name
      }
      items {
        id
        quantity
        note
        pricePerUnit
        stockItem {
          addedToOrders
          addedToRfqs
          description
          grade
          id
          imageUrl
          isArchived
          isLowStock
          minLevel
          name
          manufacturer
          manufacturerId
          note
          parentId
          pricePerUnit
          quantities {
            value
            expiresAt
            location {
              id
              name
              isDefault
            }
          }
        }
      }
      responses {
        createdAt
        id
        items {
          id
          description
          imageUrl
          manufacturer
          manufacturerId
          name
          note
          pricePerUnit
          quantity
          rfqItemId
        }
        note
        rfqId
        vendor {
          contactEmail
          contactName
          hidden
          id
          name
          note
          website
        }
      }
    }
  }
`;

export const SEARCH_PRODUCT = gql`
  query searchProduct($text: String!) {
    searchProduct(text: $text) {
      category
      description
      externalId
      imageUrlLarge
      manufacturer
      name
      subCategory
    }
  }
`;
