import { ITenant, useCurrentTenantQuery } from "interfaces/graphql";
import { useEffect, useState } from "react";

import { emptyCurrentTenant } from "../constants/constants";

const useFetchTenantDetails = () => {
  const [currentTenant, setCurrentTenant] =
    useState<ITenant>(emptyCurrentTenant);

  const { data, loading, error } = useCurrentTenantQuery({
    fetchPolicy: "network-only", // Always fetches from network when the component is rendered
    nextFetchPolicy: "cache-and-network", // Fetches from network in the background
  });

  useEffect(() => {
    if (data && data.currentTenant) {
      setCurrentTenant(data.currentTenant);
    }
  }, [data]);

  return { currentTenant, loading, error };
};

export default useFetchTenantDetails;
