import * as Yup from "yup";

import { Button, Grid, TextField } from "@mui/material";

import { Controller } from "react-hook-form";
import { IVendorRfqItem } from "modules/VendorRfqResponse/interfaces";
import { Item } from "components/Form/ItemStyles/Item";
import NumberFormat from "react-number-format";
import { VendorRfqResponseActions } from "modules/VendorRfqResponse/reducer/VendorRfqResponseReducer";
import { useDialog } from "context/DialogContext/DialogContext";
import { useForm } from "react-hook-form";
import { useSnackbar } from "modules/Stock/context/SnackbarContext";
import { useState } from "react";
import useVendorRfqResponse from "modules/VendorRfqResponse/hooks/useVendorRfqResponse";
import { yupResolver } from "@hookform/resolvers/yup";

interface FormValues {
  name: string;
  quantity: number;
  vendorNote: string;
  photo: File;
  parentId: string;
  pricePerUnit: string;
  description: string;
  manufacturer: string;
  manufacturerId: string;
}

interface IAddUpdateVendorItemFormProps {
  item?: IVendorRfqItem;
}

const AddUpdateVendorItemForm = ({ item }: IAddUpdateVendorItemFormProps) => {
  const { state, dispatch } = useVendorRfqResponse();

  const { showMessage } = useSnackbar();
  const { closeDialog } = useDialog();

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").max(200),
    note: Yup.string().max(1000),
    quantity: Yup.number().required("Quantity is required"),
    vendorNote: Yup.string().max(1000),
    manufacturer: Yup.string().max(100),
    manufacturerId: Yup.string().max(100),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: item?.name || "",
      vendorNote: item?.vendorNote || "",
      pricePerUnit: item?.pricePerUnit ? `£${item.pricePerUnit}` : "",
      description: item?.description || "",
      manufacturer: item?.manufacturer || "",
      manufacturerId: item?.manufacturerId || "",
    },
  });

  const onSubmit = (data: FormValues) => {
    setLoading(true);

    const newItem = {
      rfqItemId: `new-${data.name}`,
      quantity: data.quantity,
      name: data.name,
      description: data.description,
      manufacturer: data.manufacturer,
      manufacturerId: data.manufacturerId,
      pricePerUnit: data.pricePerUnit
        ? Number(data.pricePerUnit.split("£")[1])
        : null,
      vendorNote: data.vendorNote,
    };

    dispatch({
      type: VendorRfqResponseActions.setVendorItems,
      payload: [...state.vendorItems, newItem],
    });

    onClose();

    showMessage(
      `Item ${item ? "updated successfully" : "added to RFQ"}`,
      "success"
    );
  };

  const onClose = () => {
    reset();
    closeDialog();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container columnSpacing={{ xs: 1 }} rowSpacing={{ xs: 1 }}>
        <Grid item xs={12}>
          <Item>
            <TextField
              {...register("name")}
              label="Item name"
              size="small"
              spellCheck
              fullWidth
              autoFocus
              required
              InputLabelProps={{ shrink: true }}
            />
          </Item>
        </Grid>

        <Grid item md={6} xs={12}>
          <Item>
            <Controller
              control={control}
              name="pricePerUnit"
              render={({ field: { onChange, name, value } }) => (
                <NumberFormat
                  customInput={TextField}
                  size="small"
                  label="Price per unit"
                  variant="outlined"
                  decimalScale={2}
                  prefix={"£"}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue! <= 10000;
                  }}
                  allowNegative={false}
                  fullWidth
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors.pricePerUnit)}
                  helperText={
                    Boolean(errors.pricePerUnit)
                      ? errors.pricePerUnit?.message
                      : ""
                  }
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Item>
        </Grid>

        <Grid item md={6} xs={12}>
          <Item>
            <Controller
              control={control}
              name="quantity"
              render={({ field: { onChange, name, value } }) => (
                <NumberFormat
                  customInput={TextField}
                  size="small"
                  label="Quantity"
                  variant="outlined"
                  decimalScale={0}
                  disabled={loading}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue! <= 10000;
                  }}
                  allowNegative={false}
                  fullWidth
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors.quantity)}
                  helperText={
                    Boolean(errors.quantity) ? errors.quantity?.message : ""
                  }
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Item>
        </Grid>

        <Grid item md={6} xs={12}>
          <Item>
            <TextField
              {...register("manufacturer")}
              label="Manufacturer"
              size="small"
              spellCheck
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Item>
        </Grid>

        <Grid item md={6} xs={12}>
          <Item>
            <TextField
              {...register("manufacturerId")}
              label="Manufacturer ID"
              size="small"
              spellCheck
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Item>
        </Grid>
        <Grid item md={12} xs={12}>
          <Item>
            <TextField
              {...register("vendorNote")}
              size="small"
              spellCheck
              fullWidth
              multiline
              rows={4}
              placeholder="Leave your message here..."
              inputProps={{ style: { fontSize: ".85rem" } }}
            />
          </Item>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outlined" type="submit" disabled={loading}>
            Add
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddUpdateVendorItemForm;
