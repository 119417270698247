import { Box, Typography } from "@mui/material";

import { IVendorRfqItem } from "modules/VendorRfqResponse/interfaces";
import useVendorRfqResponse from "modules/VendorRfqResponse/hooks/useVendorRfqResponse";

const getRfqTotal = (items: IVendorRfqItem[]) => {
  if (!items) return "0.00";
  return items
    .reduce((acc: number, item: any) => {
      return acc + (item.quantity || 0) * (item?.pricePerUnit || 0);
    }, 0)
    .toFixed(2);
};

const RFQTotal = () => {
  const { state } = useVendorRfqResponse();
  const items: IVendorRfqItem[] = [...state.userItems, ...state.vendorItems];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
      }}
    >
      <Typography fontSize="1rem" mr="1rem">
        Total:
      </Typography>
      <Typography fontSize="1rem" fontWeight={700}>
        £{getRfqTotal(items)}
      </Typography>
    </Box>
  );
};

export default RFQTotal;
