import { DialogContent, DialogTitle } from "@mui/material";

import AddUpdateVendorItemForm from "modules/VendorRfqResponse/components/AddUpdateVendorItemForm/AddUpdateVendorItemForm";
import { IVendorRfqItem } from "modules/VendorRfqResponse/interfaces";

interface IAddUpdateVendorItemDialogProps {
  item?: IVendorRfqItem;
}

const AddUpdateVendorItemDialog = ({
  item,
}: IAddUpdateVendorItemDialogProps) => {
  return (
    <>
      <DialogTitle>Add Product to RFQ</DialogTitle>
      <DialogContent>
        <AddUpdateVendorItemForm item={item} />
      </DialogContent>
    </>
  );
};

export default AddUpdateVendorItemDialog;
