import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from "@mui/material";

import { Link } from "react-router-dom";
import useScreenSize from "hooks/useScreenSize/useScreenSize";

interface INavListItemProps {
  to: string;
  icon: React.ReactNode;
  text: string;
  selected: boolean;
  open: boolean;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  tooltipText?: string;
}

const NavListItem = ({
  to,
  icon,
  text,
  selected,
  open,
  onClose,
  tooltipText,
}: INavListItemProps) => {
  const { isDesktop } = useScreenSize();
  const theme = useTheme();

  const title =
    text === "RFQs" ? "Request for Quotation" : open ? "" : tooltipText || text;

  return (
    <Tooltip title={title} placement="right" arrow>
      <Link to={to} style={{ textDecoration: "none" }}>
        <ListItemButton
          key={text}
          selected={selected}
          disableRipple
          onClick={() => {
            if (!isDesktop && onClose) {
              onClose(false);
            }
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "0",
              mr: open ? 2 : "auto",
              justifyContent: "center",
              color: selected
                ? theme.palette.primary.main
                : theme.palette.grey[700],
            }}
          >
            {icon}
          </ListItemIcon>

          <ListItemText
            primary={text}
            sx={{
              opacity: open ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
              // fontWeight: 500,
              color: selected
                ? theme.palette.primary.main
                : theme.palette.grey[700],
            }}
          />
        </ListItemButton>
      </Link>
    </Tooltip>
  );
};

export default NavListItem;
