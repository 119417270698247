import { forwardRef, useEffect, useRef } from "react";

import { Checkbox } from "@mui/material";
import { ICheckboxProps } from "../interfaces";

const TableCheckbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox color="default" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export default TableCheckbox;
