import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import React, { FormEvent, useEffect, useRef, useState } from "react";

import AddSharpIcon from "@mui/icons-material/AddSharp";
import CreateVendorDialog from "modules/Vendors/components/CreateVendorDialog/CreateVendorDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDialog } from "context/DialogContext/DialogContext";

const MobileVendorsAppBarVerticalMenu = () => {
  const { openDialog } = useDialog();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  // Handle opening and closing of the menu
  const handleToggle = (e: FormEvent) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  // Function to open the Create Vendor dialog
  const onCreateVendorClick = () => {
    setOpen(false);
    openDialog(<CreateVendorDialog />);
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <div>
          <IconButton
            ref={anchorRef}
            id="vendor-menu-button"
            aria-controls={open ? "vendor-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => handleToggle(e)}
          >
            <MoreVertIcon />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            style={{ zIndex: 99 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="vendor-menu"
                      aria-labelledby="vendor-menu-button"
                    >
                      {/* Create Vendor action */}
                      <MenuItem onClick={onCreateVendorClick}>
                        <ListItemIcon>
                          <AddSharpIcon />
                        </ListItemIcon>
                        Create Vendor
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
    </>
  );
};

export default MobileVendorsAppBarVerticalMenu;
