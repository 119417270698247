const athensGray = "#fbfbfc";
const black = "#000000";
const blue = "#2196f3";
const brightGray = "#e9ecf2";
const cerulean = "#03A9F4";
const error = "#d3302f";
const red = "#d32f2f";
const brightRed = "#a30000";
const emerald = "#33be68";
const endeavour = "#005AA6";
const fruitSalad = "#4caf50";
const gallery = "#efefef";
const gray = "#e5e7eb";
const paleSky = "#6b7280";
const rose = "#e6007e";
const selectiveYellow = "#ff9800";
const silver = "#cccccc";
const success = "#2e7c32";
const successContrast = "#E5F3DE";
const springRain = "#a0c4a7";
const white = "#ffffff";
const woodsmoke = "#131415";
const warning = "#ed6c02";
const rfqItemAddedByVendor = "#faeded";

export default {
  athensGray,
  black,
  blue,
  brightGray,
  brightRed,
  cerulean,
  error,
  red,
  emerald,
  endeavour,
  fruitSalad,
  gallery,
  gray,
  paleSky,
  rose,
  selectiveYellow,
  silver,
  success,
  successContrast,
  springRain,
  white,
  woodsmoke,
  warning,
  rfqItemAddedByVendor,
};
