import { Box, IconButton, Toolbar, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import MobileRFQAppBarVerticalMenu from "modules/RFQ/components/MobileRFQAppBarVerticalMenu/MobileRFQAppBarVerticalMenu";
import MobileRFQsAppBarVerticalMenu from "modules/RFQ/components/MobileRFQsAppBarVerticalMenu/MobileRFQsAppBarVerticalMenu";
import MobileStockAppBarVerticalMenu from "modules/Stock/components/MobileStockAppBarVerticalMenu/MobileStockAppBarVerticalMenu";
import MobileVendorsAppBarVerticalMenu from "modules/Vendors/components/MobileVendorsAppBarVerticalMenu/MobileVendorsAppBarVerticalMenu";
import SearchIcon from "@mui/icons-material/Search";
import colors from "styles/colors";
import { displayPathName } from "./utils";

interface IMobileAppToolbarProps {
  open: boolean;
  handleDrawerOpen: () => void;
}

const MobileAppToolbar = ({
  open,
  handleDrawerOpen,
}: IMobileAppToolbarProps) => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const displayName = displayPathName(pathname);

  const renderDefaultToolbar = (extraElements?: React.ReactNode) => (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box display="flex">
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ marginRight: 1 }}
        >
          <MenuIcon />
        </IconButton>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography color={colors.black} variant="h3" alignSelf="center">
          {displayName}
        </Typography>
      </Box>
      {extraElements}
    </Toolbar>
  );

  const toolbars: Record<string, React.ReactNode> = {
    "Stock Search": renderDefaultToolbar(),
    RFQ: renderDefaultToolbar(<MobileRFQAppBarVerticalMenu />),
    RFQs: renderDefaultToolbar(<MobileRFQsAppBarVerticalMenu />),
    "Add RFQ Item": renderDefaultToolbar(),
    "Purchase Orders": renderDefaultToolbar(),
    Order: renderDefaultToolbar(),
    Stock: renderDefaultToolbar(
      <Box display="flex">
        <IconButton onClick={() => navigate("/mobile/stock-search")}>
          <SearchIcon />
        </IconButton>
        <MobileStockAppBarVerticalMenu />
      </Box>
    ),
    Vendors: renderDefaultToolbar(<MobileVendorsAppBarVerticalMenu />),
  };

  return (
    <>
      {toolbars[displayName] || (
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 1,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography color={colors.black} variant="h3" alignSelf="center">
              {displayName}
            </Typography>
          </Box>
        </Toolbar>
      )}
    </>
  );
};

export default MobileAppToolbar;
