import "react-medium-image-zoom/dist/styles.css";

import { CardMedia } from "@mui/material";
import React from "react";

interface IImageDisplayProps {
  imageUrl?: string | null;
  defaultIcon: string;
  isLoading?: boolean;
}

export const CustomZoomContent = React.memo(
  ({ img, onUnzoom }: { img: JSX.Element; onUnzoom: () => void }) => {
    // Handle the unzoom action
    const handleUnzoomClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      event.stopPropagation(); // Prevent the event from closing the dialog - does not work. Dialog closes
      event.preventDefault();
      onUnzoom(); // Call the onUnzoom function provided by the Zoom component
    };

    return <div onClick={handleUnzoomClick}>{img}</div>;
  }
);

const ImageDisplay = ({
  imageUrl,
  defaultIcon,
  isLoading,
}: IImageDisplayProps) => {
  const displayImage = imageUrl || defaultIcon;

  return (
    <div style={{ margin: "0 auto" }}>
      <CardMedia
        component="img"
        style={{
          width: "100%",
          height: "280px",
          maxHeight: "280px",
          objectFit: "contain",

          filter: isLoading ? "blur(8px)" : "none",
        }}
        image={displayImage}
        alt={isLoading ? "Loading..." : "Uploaded item"}
      />
    </div>
  );
};

export default ImageDisplay;
