import React, { createContext, useEffect, useState } from "react";
import jwt_decode, { JwtPayload } from "jwt-decode";

import { useAuth0 } from "@auth0/auth0-react";

type customJwtPayload = JwtPayload & { "https://klinika-api/roles": string[] };

interface IUser {
  id: string;
  name: string;
  email: string;
  picture: string;
  roles: string[];
  organizationId: string;
  loading: boolean;
  error?: string;
}

export const initialUserState = {
  id: "",
  name: "",
  email: "",
  picture: "",
  roles: [],
  organizationId: "",
  loading: false,
  error: undefined,
};

const UserContext = createContext<IUser | undefined>(undefined);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userDetails, setUserDetails] = useState<IUser>(initialUserState);
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    async function fetchToken() {
      try {
        setUserDetails({ ...userDetails, loading: true });
        const token = await getAccessTokenSilently().then((token) => {
          const decoded = jwt_decode<customJwtPayload>(token);
          return decoded;
        });
        setUserDetails({
          id: user?.sub || "",
          name: user?.name || "",
          email: user?.email || "",
          picture: user?.picture || "",
          roles: token["https://klinika-api/roles"] || [],
          organizationId: user?.org_id,
          loading: false,
          error: undefined,
        });
      } catch (error) {
        setUserDetails({
          ...initialUserState,
          loading: false,
          error: "An error occurred. Please try again later.",
        });
      }
    }

    if (isAuthenticated) {
      fetchToken();
    } else {
      setUserDetails(initialUserState);
    }
  }, [user, isAuthenticated, getAccessTokenSilently]);

  const userDetailsValues = { ...userDetails };

  return (
    <UserContext.Provider value={userDetailsValues}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
