import { Box, Grid, TextField } from "@mui/material";

import AddRfqResponseMessage from "../AddRfqResponseMessage/AddRfqResponseMessage";
import useVendorRfqResponse from "modules/VendorRfqResponse/hooks/useVendorRfqResponse";

const VendorRfqUserNote = () => {
  const { state } = useVendorRfqResponse();

  const note = state.userNote;

  return (
    <>
      {state.userNote && (
        <Box mt="1rem">
          <TextField
            value={note}
            label="User Comment"
            multiline
            size="small"
            fullWidth
            inputProps={{ style: { fontSize: ".85rem" } }}
          />
        </Box>
      )}
      <Box mt="1rem">
        <AddRfqResponseMessage />
      </Box>
    </>
  );
};

export default VendorRfqUserNote;
