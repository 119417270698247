import { Dispatch, createContext, useEffect, useReducer } from "react";
import vendorRfqResponseReducer, {
  IVendorRfqResponseAction,
  IVendorRfqResponseState,
  VendorRfqResponseActions,
} from "../reducer/VendorRfqResponseReducer";

import { IVendorRfqResponse } from "../interfaces";
import axios from "axios";

interface IVendorRfqResponseContext {
  state: IVendorRfqResponseState;
  dispatch: Dispatch<IVendorRfqResponseAction>;
}

const initialState = {
  quoteId: "",
  rfqId: "",
  note: "",
  userItems: [],
  vendorItems: [],
  selectedItem: null,
  loading: true,
  error: null,
};

const VendorRfqResponseContext = createContext<IVendorRfqResponseContext>({
  state: initialState,
  dispatch: () => undefined,
});

const VendorRfqResponseProvider = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) => {
  const [state, dispatch] = useReducer(vendorRfqResponseReducer, initialState);

  useEffect(() => {
    if (id) {
      dispatch({
        type: VendorRfqResponseActions.setLoading,
        payload: true,
      });

      const url = `${process.env.REACT_APP_QUOTE_DATA_URL}/${id}`;
      axios
        .get(url)
        .then((res: { data: IVendorRfqResponse }) => {
          dispatch({
            type: VendorRfqResponseActions.setUserData,
            payload: {
              quoteId: res.data.id,
              userItems: res.data.items,
              userNote: res.data.note || "",
            },
          });

          dispatch({
            type: VendorRfqResponseActions.setLoading,
            payload: false,
          });
        })
        .catch((err) => {
          dispatch({
            type: VendorRfqResponseActions.setLoading,
            payload: false,
          });

          dispatch({
            type: VendorRfqResponseActions.setError,
            payload: err.response.data, // need to set to some code or message, so dialog "knows" what to display
          });
        });
    }
  }, [id]);

  const contextValue = { state, dispatch };

  return (
    <VendorRfqResponseContext.Provider value={contextValue}>
      {children}
    </VendorRfqResponseContext.Provider>
  );
};

export { VendorRfqResponseContext, VendorRfqResponseProvider };
