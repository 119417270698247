import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

interface ISwipeableDrawerContext {
  isDrawerOpen: boolean;
  drawerContent: ReactNode | null;
  openDrawer: (content: ReactNode) => void;
  closeDrawer: () => void;
}

const SwipeableDrawerContext = createContext<ISwipeableDrawerContext | null>(
  null
);

export const useSwipeableDrawer = () => {
  const context = useContext(SwipeableDrawerContext);
  if (!context) {
    throw new Error(
      "useSwipeableDrawer must be used within a SwipeableDrawerProvider"
    );
  }
  return context;
};

interface ISwipeableDrawerProviderProps {
  children: ReactNode;
}

export const SwipeableDrawerProvider: React.FC<
  ISwipeableDrawerProviderProps
> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<ReactNode | null>(null);

  const openDrawer = useCallback((content: ReactNode) => {
    setDrawerContent(content);
    setIsDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const value = {
    isDrawerOpen,
    drawerContent,
    openDrawer,
    closeDrawer,
  };

  return (
    <SwipeableDrawerContext.Provider value={value}>
      {children}
    </SwipeableDrawerContext.Provider>
  );
};
