import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";

// Protected resources. Needs token from Auth0 to access.
const AuthApolloProvider = ({ children }: { children: React.ReactElement }) => {
  const { getAccessTokenSilently } = useAuth0();

  const apiUrl = process.env.REACT_APP_GRAPHQL_URL;
  const wsUrl = process.env.REACT_APP_GRAPHQL_URL_WEB_SOCKET;

  const AuthLink = new HttpLink({
    uri: apiUrl,
    fetch: async (uri: RequestInfo, options: RequestInit = {}) => {
      const { headers } = options;
      const token = await getAccessTokenSilently();

      return window.fetch(uri, {
        ...options,
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${wsUrl}ws`,
      connectionParams: async () => {
        const token = await getAccessTokenSilently();
        return {
          authToken: token,
        };
      },
    })
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    AuthLink
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
    link: splitLink,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthApolloProvider;
