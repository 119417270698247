import Loader from "components/Loader/Loader";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

const LoginWithAuth0 = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  React.useEffect(() => {
    // Extract query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const invitation = searchParams.get("invitation");
    const organization = searchParams.get("organization");

    // Prepare the login options with the organization and invitation details
    const loginOptions = {
      authorizationParams: {
        // Redirect with the organization and invitation parameters if available
        ...(organization && { organization }),
        ...(invitation && { invitation }),
      },
    };

    // Initiate the login process, including the organization and invitation parameters
    loginWithRedirect(loginOptions);
  }, [loginWithRedirect, location.search]);

  return <Loader />;
};

export default LoginWithAuth0;
