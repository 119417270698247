import { ComponentType } from "react";
import Loader from "components/Loader/Loader";
import { withAuthenticationRequired } from "@auth0/auth0-react";

export const AuthenticationGuard = ({
  component,
}: {
  component: ComponentType<object>;
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader />,
  });

  return <Component />;
};
