import { Box, IconButton, Popover } from "@mui/material";

import LightbulbIcon from "@mui/icons-material/Lightbulb";
import colors from "styles/colors";
import { useState } from "react";

const InfoPopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "tip-popover" : undefined;

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        padding: "1rem",
        marginTop: ".1rem",
        marginLeft: ".1rem",
        fontSize: ".8rem",
        textAlign: "left",
      }}
    >
      <IconButton onClick={handleClick} aria-describedby={id}>
        <LightbulbIcon htmlColor={colors.selectiveYellow} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ul style={{ padding: "1rem 2rem" }}>
          <li>
            To <b>update</b> prices and comments, <b>click</b> on a row item to
            open a pop-up window.
          </li>
          <li>
            In the pop-up, <b>enter</b> the price and adjust other fields as
            needed.
          </li>
          <li>
            If you don't have a particular item,
            <b> leave</b> the price blank.
          </li>

          <li>
            Click <b>'Send'</b> to send the updated information back to us.
          </li>
        </ul>
      </Popover>
    </Box>
  );
};

export default InfoPopover;
