import { Box, Button, Grid, Typography } from "@mui/material";
import {
  IVendorRfqItem,
  IVendorRfqTableData,
} from "modules/VendorRfqResponse/interfaces";
import { useCallback, useMemo } from "react";

import AddUpdateVendorItemDialog from "../AddUpdateVendorItemDialog/AddUpdateVendorItemDialog";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { CustomZoomContent } from "components/ImageContainer/ImageDisplay/ImageDisplay";
import ImageTableRowItem from "components/ImageContainer/ImageTableRowItem/ImageTableRowItem";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Table from "components/Table/Table";
import UpdateVendorQuoteRFQItemDialog from "modules/VendorRfqResponse/components/UpdateVendorQuoteRFQItemDialog/UpdateVendorQuoteRFQItemDialog";
import VendorQuoteRemoveRFQItem from "modules/VendorRfqResponse/components/VendorQuoteRemoveRFQItem/VendorQuoteRemoveRFQItem";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Zoom from "react-medium-image-zoom";
import colors from "styles/colors";
import { useDialog } from "context/DialogContext/DialogContext";
import useVendorRfqResponse from "modules/VendorRfqResponse/hooks/useVendorRfqResponse";

const VendorsQuoteRFQTable = () => {
  const { openDialog } = useDialog();

  const { state } = useVendorRfqResponse();

  const onItemImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const memoizedZoomContent = useCallback((zoomProps: any) => {
    return <CustomZoomContent {...zoomProps} />;
  }, []);

  const mapToTableData = useCallback((items: IVendorRfqItem[]) => {
    return items.map((item) => {
      return {
        id: item.rfqItemId || `new-${item.name}`,
        altId: item.rfqItemId || `new-${item.name}`,
        name: item.name,
        manufacturer: item.manufacturer,
        manufacturerId: item.manufacturerId,
        description: item.description,
        quantity: item.quantity,
        note: item.note,
        vendorNote: item.vendorNote,
        imageUrl: item.imageUrl,
        pricePerUnit: item.pricePerUnit || 0,
        subTotal: String((item?.quantity || 0) * (item?.pricePerUnit || 0)),
      };
    });
  }, []);

  const onAddVendorItem = () => {
    openDialog(<AddUpdateVendorItemDialog />);
  };

  const columns = useMemo(
    () => [
      {
        id: "ID",
        accessor: "Id",
      },
      {
        Header: "Image",
        accessor: "imageUrl",
        Cell: ({ value }: { value: string }) => {
          return (
            <Box onClick={onItemImageClick}>
              <Zoom ZoomContent={memoizedZoomContent}>
                <ImageTableRowItem image={value} />
              </Zoom>
            </Box>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }: { value: string }) => {
          if (!value) {
            return <Box height={"1.15rem"}></Box>;
          } else {
            return value;
          }
        },
      },

      {
        Header: "Manufacturer ID",
        accessor: "manufacturerId",
      },
      {
        Header: "Note",
        accessor: "note",
        Cell: ({ value, row }: { value: string; row: any }) => {
          const vendorNote = row.original.vendorNote;
          if (!value && !vendorNote) return "";

          const displayValue =
            value && value.length > 10 ? `${value.slice(0, 10)}...` : value;

          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {displayValue}

              {value && !vendorNote && (
                <WarningAmberIcon
                  sx={{ color: colors.selectiveYellow, ml: ".25rem" }}
                />
              )}

              {vendorNote && (
                <MailOutlineIcon fontSize="small" color="success" />
              )}
            </Box>
          );
        },
      },
      {
        Header: <Box sx={{ textAlign: "center" }}>Quantity</Box>,
        accessor: "quantity",
        style: { textAlign: "center" },
      },

      {
        Header: <Box sx={{ textAlign: "center" }}>Unit Price</Box>,
        accessor: "pricePerUnit",
        style: { textAlign: "center" },
        Cell: ({ value, row }: { value: number | string; row: any }) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1, // Adds some space between the price and the icon
              }}
            >
              <Typography component="span">
                £{parseFloat(String(value)).toFixed(2)}
              </Typography>
              <ControlPointIcon
                sx={{ color: colors.selectiveYellow, cursor: "pointer" }}
              />
            </Box>
          );
        },
      },
      {
        Header: <Box sx={{ textAlign: "center" }}>Sub Total</Box>,
        accessor: "subTotal",
        style: { textAlign: "center" },
        Cell: ({ value }: { value: string }) => {
          if (!value) return "";
          return `£${parseFloat(value).toFixed(2)}`;
        },
      },
      {
        Header: <Box sx={{ textAlign: "center" }}>Action</Box>,
        accessor: "altId",
        style: { textAlign: "center" },
        Cell: ({ row }: { row: { original: IVendorRfqTableData } }) => {
          // only show remove button for new items
          if (
            typeof row.original.id === "string" &&
            row.original.id.includes("new-")
          ) {
            return <VendorQuoteRemoveRFQItem item={row.original} />;
          }
        },
      },
    ],
    []
  );

  const tData = useMemo(
    () => mapToTableData([...state.userItems, ...state.vendorItems]),
    [state]
  );

  const onRowClick = (item: IVendorRfqTableData) => {
    openDialog(<UpdateVendorQuoteRFQItemDialog item={item} />);
  };

  return (
    <>
      <Grid item xs={12} mb={2}>
        <Table columns={columns} data={tData} onRowClick={onRowClick} />
      </Grid>
      <Grid item xs={12} mb={2} display="flex" justifyContent="flex-start">
        <Button variant="outlined" onClick={onAddVendorItem}>
          Add item
        </Button>
      </Grid>
    </>
  );
};

export default VendorsQuoteRFQTable;
