import { Box, Button, Grid, Typography } from "@mui/material";
import {
  IVendorRfqResponseState,
  VendorRfqResponseActions,
} from "modules/VendorRfqResponse/reducer/VendorRfqResponseReducer";
import { useEffect, useState } from "react";

import ConfirmVendorRFQResponseDialog from "modules/VendorRfqResponse/components/ConfirmVendorRFQResponseDialog/ConfirmVendorRFQResponseDialog";
import { IRfqResponsePayload } from "modules/VendorRfqResponse/interfaces";
import Loader from "components/Loader/Loader";
import MessageDialog from "components/MessageDialog/MessageDialog";
import RFQTotal from "modules/VendorRfqResponse/components/RFQTotal/RFQTotal";
import SendIcon from "@mui/icons-material/Send";
import Status from "components/Status/Status";
import VendorRfqUserNote from "modules/VendorRfqResponse/components/VendorRfqUserNote/VendorRfqUserNote";
import VendorsQuoteRFQTable from "modules/VendorRfqResponse/components/VendorsQuoteRFQTable/VendorsQuoteRFQTable";
import axios from "axios";
import colors from "styles/colors";
import { useDialog } from "context/DialogContext/DialogContext";
import useVendorRfqResponse from "modules/VendorRfqResponse/hooks/useVendorRfqResponse";

const mapStateToPayload = (
  state: IVendorRfqResponseState
): IRfqResponsePayload => {
  const userItems = state.userItems.map((item) => ({
    rfqItemId: item.rfqItemId as number,
    quantity: item.quantity,
    pricePerUnit: item.pricePerUnit || undefined,
    note: item.vendorNote,
    manufacturerId: item.manufacturerId || undefined,
    manufacturer: item.manufacturer || undefined,
  }));

  const vendorItems = state.vendorItems.map((item) => ({
    name: item.name,
    quantity: item.quantity,
    note: item.vendorNote,
    pricePerUnit: item.pricePerUnit || undefined,
    description: item.description || undefined,
    manufaceurerId: item.manufacturerId || undefined,
    manufacturer: item.manufacturer || undefined,
  }));

  const items = [...userItems, ...vendorItems];

  return {
    note: state.vendorNote || "",
    items: items,
  };
};

const VendorRFQResponseContainer = () => {
  const id = window.location.pathname.split("/")[2];

  const { state, dispatch } = useVendorRfqResponse();
  const { openDialog } = useDialog();

  const [submitted, setSubmitted] = useState(false);

  const onSendClick = () => {
    openDialog(
      <ConfirmVendorRFQResponseDialog
        onConfirmClick={onConfirmClick}
        payload={mapStateToPayload(state)}
      />
    );
  };

  const onConfirmClick = () => {
    dispatch({
      type: VendorRfqResponseActions.setLoading,
      payload: true,
    });
    const url = `${process.env.REACT_APP_QUOTE_DATA_URL}/${id}`;
    const payload = mapStateToPayload(state);

    axios
      .post(url, payload)
      .then(() => {
        setSubmitted(true);

        dispatch({
          type: VendorRfqResponseActions.setLoading,
          payload: false,
        });
        openDialog(
          <MessageDialog message="Your quote was successfully submitted. You can close this window now." />
        );
      })
      .catch(() => {
        dispatch({
          type: VendorRfqResponseActions.setLoading,
          payload: false,
        });

        dispatch({
          type: VendorRfqResponseActions.setError,
          payload: true,
        });
        openDialog(
          <MessageDialog message="There was a problem while sending the data. Please re-fresh the page, try again, or contact us directly." />
        );
      });
  };

  useEffect(() => {
    if (state.error) {
      const message = (state.error as string) || "Quote page unavailable";
      openDialog(<MessageDialog message={message} />);
    }
  }, [state.error]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mt: "-2rem",
        }}
      >
        {state.error || submitted ? null : (
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={onSendClick}
            disabled={state.loading}
          >
            Send
          </Button>
        )}
      </Box>
      {state.loading && (
        <Box sx={{ justifyContent: "center", display: "flex", width: "100%" }}>
          <Loader />
        </Box>
      )}
      {!state.error && (
        <Grid container gap={1} border={1} borderColor={colors.gray} p={2}>
          {/* TITLE */}
          <Grid item xs={12} md={8}>
            <Typography fontSize=".85rem" fontWeight={700}>
              Request for Quotation
            </Typography>
            <Box fontSize="1.5rem" fontWeight={700}>
              <Status status="DRAFT" />
            </Box>
          </Grid>

          {/* TABLE */}
          {state.error ? null : <VendorsQuoteRFQTable />}

          {/* COMMENT & TOTAL */}
          {state.error ? null : (
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs={5}>
                  <VendorRfqUserNote />
                </Grid>
                <Grid item xs={3}>
                  <RFQTotal />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default VendorRFQResponseContainer;
