import { Badge, IconButton } from "@mui/material";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import React from "react";

interface INotificationBellProps {
  count: number;
  handleNotificationClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const NotificationBell = ({
  count,
  handleNotificationClick,
}: INotificationBellProps) => {
  return (
    <IconButton onClick={handleNotificationClick}>
      <Badge badgeContent={count} color="error">
        <NotificationsNoneIcon />
      </Badge>
    </IconButton>
  );
};

export default NotificationBell;
