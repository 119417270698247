import { Grid, colors } from "@mui/material";
import React, { useRef, useState } from "react";

import Button from "@mui/material/Button";
import ImageCompression from "browser-image-compression";
import Typography from "@mui/material/Typography";

interface ImageUploaderProps {
  onFileUploadSuccess: (file: Blob) => void;
  isFileUploaded: boolean;
  folderOnEdit?: boolean;
  folderId?: string;
}

const ImageNewUploader: React.FC<ImageUploaderProps> = ({
  onFileUploadSuccess,
  isFileUploaded,
}) => {
  const [imageUploadError, setImageUploadError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFile = async (uploadedFile: File) => {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    try {
      const compressedFile = await ImageCompression(uploadedFile, options);

      if (compressedFile.size > 2000000) {
        setImageUploadError("Compressed File is larger than 2MB");
        return;
      }

      onFileUploadSuccess(compressedFile);
      setImageUploadError(null);
    } catch (error) {
      setImageUploadError("Error compressing image");
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (!uploadedFile) return;

    if (!uploadedFile.type.match(/image\/(jpeg|png|jpg|svg|gif)/)) {
      setImageUploadError("Invalid file type");
      return;
    }

    handleFile(uploadedFile);
  };

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center" gap={2}>
        {!isFileUploaded && (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={triggerFileInput}
          >
            Upload photo
          </Button>
        )}

        <input
          ref={fileInputRef}
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          accept=".jpg, .jpeg, .svg, .png, .gif"
          onChange={handleInputChange}
        />

        {imageUploadError && (
          <Typography
            variant="body2"
            sx={{ color: colors.red, marginBottom: "1rem" }}
          >
            {imageUploadError}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ImageNewUploader;
