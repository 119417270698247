import { createTheme, responsiveFontSizes } from "@mui/material";

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
    },
    typography: {
      h1: {
        fontSize: "3rem",
      },
      h2: {
        fontSize: "2.5rem",
      },
      h3: {
        fontSize: "2rem",
      },
      h4: {
        fontSize: "1.5rem",
      },
      h5: {
        fontSize: "1.25rem",
      },
      h6: {
        fontSize: "1rem",
      },
      subtitle1: {
        fontSize: "0.875rem",
      },
      subtitle2: {
        fontSize: "0.75rem",
      },
      body1: {
        fontSize: "1rem",
      },
      body2: {
        fontSize: "0.875rem",
      },
      caption: {
        fontSize: "0.75rem",
      },
      overline: {
        fontSize: "0.625rem",
      },
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              // backgroundColor: colors.successContrast,
            },
            "&.Mui-selected:hover": {
              // backgroundColor: colors.successContrast,
            },
          },
        },
      },
    },
  })
);
