import { useEffect, useState } from "react";

import { TextField } from "@mui/material";
import { VendorRfqResponseActions } from "modules/VendorRfqResponse/reducer/VendorRfqResponseReducer";
import useDebounce from "utils/useDebounce";
import useVendorRfqResponse from "modules/VendorRfqResponse/hooks/useVendorRfqResponse";

const AddRfqResponseMessage = () => {
  const [comment, setComment] = useState<string>("");
  const debouncedSearchTerm = useDebounce(comment, 500);

  const { dispatch } = useVendorRfqResponse();

  useEffect(() => {
    dispatch({
      type: VendorRfqResponseActions.setVendorNote,
      payload: debouncedSearchTerm,
    });
  }, [debouncedSearchTerm, dispatch]);

  return (
    <TextField
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      multiline
      InputLabelProps={{ shrink: true }}
      label="Vendor note"
      size="small"
      fullWidth
      inputProps={{ style: { fontSize: ".85rem" } }}
      spellCheck
      placeholder="Leave your message here..."
    />
  );
};

export default AddRfqResponseMessage;
