import { OrderStatus, RfqStatus, StockAuditStatus } from "interfaces/graphql";

import { Chip } from "@mui/material";

interface IStatusProps {
  status: string;
}

const Status = ({ status }: IStatusProps) => {
  const formatStatus = (status: string) => {
    switch (status) {
      case RfqStatus.Draft:
      case StockAuditStatus.Draft:
        return "Draft";
      case RfqStatus.Sent:
        return "Sent";
      case RfqStatus.Received:
        return "Received";
      case OrderStatus.Cancelled:
        return "Cancelled";
      case OrderStatus.Pending:
        return "Pending";
      case OrderStatus.FollowUp:
        return "Follow Up";
      case OrderStatus.Delivered:
        return "Delivered";
      case StockAuditStatus.Submitted:
        return "Submitted";
      case StockAuditStatus.Completed:
        return "Completed";

      default:
        return "Unknown";
    }
  };
  const statusColor = (status: string) => {
    switch (status) {
      case RfqStatus.Draft:
      case OrderStatus.Pending:
        return "primary";
      case RfqStatus.Sent:
      case OrderStatus.FollowUp:
      case StockAuditStatus.Submitted:
        return "warning";
      case RfqStatus.Received:
      case OrderStatus.Delivered:
      case StockAuditStatus.Completed:
        return "success";
      default:
        return "default";
    }
  };

  return (
    <Chip
      sx={{
        height: "18px",
      }}
      size="small"
      variant="outlined"
      label={formatStatus(status)}
      color={statusColor(status)}
    />
  );
};

export default Status;
