import {
  DrawerHeader,
  MobileDrawer as MobileDrawerStyled,
} from "./DrawerStyles";

import BrandLogo from "components/BrandLogo/BrandLogo";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import NavList from "components/NavList/NavList";
import { useTheme } from "@mui/material/styles";

interface IMobileDrawerProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileDrawer = ({ open, setOpen }: IMobileDrawerProps) => {
  const theme = useTheme();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <MobileDrawerStyled
      variant="temporary"
      open={open}
      onClose={handleDrawerClose}
      onOpen={handleDrawerOpen}
      ModalProps={{ keepMounted: true }} // Better open performance on mobile.
    >
      <DrawerHeader
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BrandLogo />

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>

      <NavList open={open} onClose={handleDrawerClose} />
    </MobileDrawerStyled>
  );
};

export default MobileDrawer;
