import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const MessageDialog = ({ message }: { message: string }) => {
  return (
    <Dialog open={true}>
      <DialogTitle>Message</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default MessageDialog;
