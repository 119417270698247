import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(0),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
