import {
  IFolder,
  IItem,
  useFoldersQuery,
  useItemsSummaryQuery,
} from "interfaces/graphql";
import { createContext, useEffect, useMemo, useState } from "react";

import { ApolloError } from "@apollo/client";

interface IAllStockContext {
  folders: IFolder[] | undefined;
  items: IItem[] | undefined;
  loading: boolean;
  error?: ApolloError;
}

const initialAllStockState: IAllStockContext = {
  folders: [],
  items: [],
  loading: true,
  error: undefined,
};

const AllStockContext = createContext<any>(initialAllStockState);

const AllStockProvider = ({ children }: { children: React.ReactNode }) => {
  const [stock, setStock] = useState<IAllStockContext>(initialAllStockState);

  const {
    data: itemsData,
    loading: itemsLoading,
    error: itemsError,
    // there's a hard limit from server 100 so we need to fetch all items
  } = useItemsSummaryQuery({
    variables: {
      filter: {
        limit: 99999,
      },
    },
    ...{
      fetchPolicy: "cache-first", // Initially loads from cache
      nextFetchPolicy: "cache-and-network", // Fetches from network in the background
    },
  });
  const {
    data: foldersData,
    loading: foldersLoading,
    error: foldersError,
  } = useFoldersQuery({
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (itemsData && foldersData) {
      const folders = foldersData.folders;
      const items = itemsData.itemsSummary;
      setStock({
        folders: folders as IFolder[],
        items: items as IItem[],
        loading: itemsLoading || foldersLoading,
      });
    }
    if (itemsData && !itemsData.itemsSummary) {
      setStock({
        folders: [],
        items: [],
        loading: false,
        error: new ApolloError({
          errorMessage: "Stock Map query did not return data",
        }),
      });
    }
  }, [itemsData, foldersData, itemsLoading, foldersLoading]);

  useEffect(() => {
    if (itemsError || foldersError) {
      setStock({
        folders: undefined,
        items: undefined,
        loading: false,
        error: itemsError || foldersError,
      });
    }
  }, [foldersError, itemsError]);

  const value = useMemo(() => {
    return { stock, setStock };
  }, [stock, setStock]);

  return (
    <AllStockContext.Provider value={value}>
      {children}
    </AllStockContext.Provider>
  );
};

export { AllStockContext, AllStockProvider };
