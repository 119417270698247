import { Alert, AlertColor, Snackbar, SnackbarProps } from "@mui/material";
import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";

import TaskAltIcon from "@mui/icons-material/TaskAlt";

interface SnackbarContextType {
  showMessage: (message: string, severity?: AlertColor) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "info" as AlertColor,
  });

  const showMessage = (message: string, severity: AlertColor = "info") => {
    setSnackbarState({ open: true, message, severity });
  };

  const handleSnackbarClose: SnackbarProps["onClose"] = (_event, reason) => {
    if (reason !== "clickaway") {
      setSnackbarState((prevState) => ({ ...prevState, open: false }));
    }
  };

  const handleClose = (_event: React.SyntheticEvent<Element, Event>) => {
    setSnackbarState((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarState.severity}
          iconMapping={{
            success: <TaskAltIcon sx={{ color: "limegreen" }} />, // customize the color here
          }}
          sx={{
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)", // for dark mode
            color: "white", // text color
          }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
