import { Box } from "@mui/material";
import { ReactNode } from "react";
import useScreenSize from "hooks/useScreenSize/useScreenSize";

const PageContainer = ({ children }: { children: ReactNode }) => {
  const { isMobile, isDesktop } = useScreenSize();

  return (
    <Box
      sx={{
        // maxWidth: "1240px",
        padding: isDesktop ? "0px" : isMobile ? "12px" : "24px",
        maxWidth: "100vw", // Ensures it does not exceed the viewport width
        overflowX: "hidden", // Prevents horizontal overflow
      }}
    >
      {children}
    </Box>
  );
};

export default PageContainer;
