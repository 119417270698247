import { Grid, Typography } from "@mui/material";

import BrandLogo from "assets/logo/dental_assist_logo_white_BG_horizontal.png";
import { DialogProvider } from "context/DialogContext/DialogContext";
import GenericDialog from "components/Dialog/GenericDialog";
import InfoPopover from "modules/VendorRfqResponse/components/InfoPopover/InfoPopover";
import PageContainer from "components/PageContainer/PageContainer";
import VendorRFQResponseContainer from "features/VendorRFQResponse/containers/VendorRFQResponseContainer";
import { VendorRfqResponseProvider } from "modules/VendorRfqResponse/context/VendorRfqResponseContext";

const VendorRFQResponsePage = () => {
  const id = window.location.pathname.split("/")[2];

  return (
    <VendorRfqResponseProvider id={id}>
      <DialogProvider>
        <PageContainer>
          <Grid container sx={{ p: "2rem" }} gap={4}>
            <Grid item xs={12} alignContent="center" textAlign={"center"}>
              <img src={BrandLogo} alt="Denta Logo" width={250} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography variant="h5">
                I would like to check the prices for these products.
              </Typography>
              <InfoPopover />
            </Grid>
            <VendorRFQResponseContainer />
          </Grid>
        </PageContainer>
        <GenericDialog />
      </DialogProvider>
    </VendorRfqResponseProvider>
  );
};

export default VendorRFQResponsePage;
