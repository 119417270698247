import { ITenant } from "interfaces/graphql";

export const emptyCurrentTenant: ITenant = {
  enabled: false,
  externalId: "",
  id: "",
  logoUrl: "",
  name: "",
  ordersEmail: "",
  ordersEmailName: "",
};
