import CacheBuster from "react-cache-buster";
import CssBaseline from "@mui/material/CssBaseline";
import { DialogProvider } from "context/DialogContext/DialogContext";
import GenericDialog from "components/Dialog/GenericDialog";
import GlobalSwipeableDrawer from "components/GlobalSwipeableDrawer/GlobalSwipeableDrawer";
import Loader from "components/Loader/Loader";
import LoginWithAuth0 from "components/LoginWithAuth0/LoginWithAuth0";
import MiniDrawer from "components/Drawer/Drawer";
import RoutesComponent from "routes/Routes";
import { SelectedStockItemProvider } from "modules/Stock/context/SelectedStockItemContext";
import { SwipeableDrawerProvider } from "context/SwipeableDrawerContext/SwipeableDrawerContext";
import { ThemeProvider } from "@mui/material";
import { UserProvider } from "modules/User/context/UserContext";
import VendorRFQResponsePage from "features/VendorRFQResponse/pages/VendorRFQResponsePage";
import packageJson from "../../../package.json";
import { theme } from "styles/theme";
import { useAuth0 } from "@auth0/auth0-react";

const App = () => {
  const version = packageJson.version;
  const isProduction = process.env.NODE_ENV === "production";

  const path = window.location.pathname.split("/")[1];
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <Loader />;
  }

  if (path === "rfq") {
    // Unauthenticated users can view the VendorRFQResponsePage
    return <VendorRFQResponsePage />;
  }

  if (!isAuthenticated) {
    return <LoginWithAuth0 />;
  }

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <ThemeProvider theme={theme}>
        <UserProvider>
          <SwipeableDrawerProvider>
            <DialogProvider>
              {/* TODO: investigate if this Provider is needed */}
              <SelectedStockItemProvider>
                <MiniDrawer>
                  <CssBaseline />
                  <RoutesComponent />
                  <GlobalSwipeableDrawer />
                  <GenericDialog />
                </MiniDrawer>
              </SelectedStockItemProvider>
            </DialogProvider>
          </SwipeableDrawerProvider>
        </UserProvider>
      </ThemeProvider>
    </CacheBuster>
  );
};

export default App;
