import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0();

  const onLogoutClick = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return isAuthenticated ? (
    <Button variant="outlined" color="primary" onClick={onLogoutClick}>
      Sign Out
    </Button>
  ) : (
    <></>
  );
};

export default LogoutButton;
