import * as Yup from "yup";

import { Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  IVendorRfqItem,
  IVendorRfqTableData,
} from "modules/VendorRfqResponse/interfaces";

import { Item } from "components/Form/ItemStyles/Item";
import NumberFormat from "react-number-format";
import { VendorRfqResponseActions } from "modules/VendorRfqResponse/reducer/VendorRfqResponseReducer";
import { useDialog } from "context/DialogContext/DialogContext";
import { useSnackbar } from "modules/Stock/context/SnackbarContext";
import { useState } from "react";
import useVendorRfqResponse from "modules/VendorRfqResponse/hooks/useVendorRfqResponse";
import { yupResolver } from "@hookform/resolvers/yup";

interface FormValues {
  name: string;
  note: string;
  vendorNote: string;
  photo: File;
  parentId: string;
  pricePerUnit: string;
  description: string;
  quantity: number | null;
  manufacturer: string;
  manufacturerId: string;
  imageUrl: string;
}

interface IUpdateProductToRfqFormProps {
  item: IVendorRfqTableData;
}

const mapTableDataToVendorRfqItem = (
  item: IVendorRfqTableData
): IVendorRfqItem => {
  return {
    rfqItemId: item.id,
    name: item.name,
    quantity: item.quantity,
    note: item.note,
    vendorNote: item.vendorNote || "",
    description: item.description,
    manufacturerId: item.manufacturerId,
    manufacturer: item.manufacturer,
    pricePerUnit: item.pricePerUnit,
    imageUrl: item.imageUrl,
  };
};

const UpdateProductToRfqForm = ({ item }: IUpdateProductToRfqFormProps) => {
  const isVendorAddedItem =
    typeof item?.id === "string" && item.id.includes("new-");

  const { showMessage } = useSnackbar();
  const { closeDialog } = useDialog();

  const { state, dispatch } = useVendorRfqResponse();

  const validationSchema = Yup.object().shape({
    pricePerUnit: Yup.string().required("Price per unit is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: item?.name || "",
      note: item?.note || "",
      vendorNote: item?.vendorNote || "",
      description: item?.description || "",
      manufacturer: item?.manufacturer || "",
      manufacturerId: item?.manufacturerId || "",
      quantity: item?.quantity || null,
      imageUrl: item?.imageUrl || "",
      pricePerUnit: item?.pricePerUnit
        ? `£${Number(item?.pricePerUnit).toFixed(2)}`
        : undefined,
    },
  });

  const onSubmit = (data: FormValues) => {
    const collectionToUpdate = isVendorAddedItem
      ? state.vendorItems
      : state.userItems;

    const updatedItem = {
      ...item,
      pricePerUnit: data.pricePerUnit
        ? Number(data.pricePerUnit.split("£")[1])
        : null,
      vendorNote: data.vendorNote,
    };

    const updatedCollection = collectionToUpdate.map((item) => {
      if (item.rfqItemId === updatedItem.id) {
        return mapTableDataToVendorRfqItem(updatedItem);
      }
      return item;
    });

    if (isVendorAddedItem) {
      dispatch({
        type: VendorRfqResponseActions.setVendorItems,
        payload: updatedCollection,
      });
    } else {
      dispatch({
        type: VendorRfqResponseActions.setUserItems,
        payload: updatedCollection,
      });
    }

    showMessage("Item updated successfully", "success");
    closeDialog();
  };

  const onClose = () => {
    closeDialog();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container columnSpacing={{ xs: 1 }} rowSpacing={{ xs: 1 }}>
        <Grid md={12} xs={12} item>
          <Grid container>
            <Grid item xs={12}>
              <Item>
                <TextField
                  {...register("name")}
                  label="Name"
                  size="small"
                  spellCheck
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  InputProps={{
                    readOnly: !isVendorAddedItem,
                  }}
                />
              </Item>
            </Grid>

            <Grid item md={12} xs={12} mb={2}>
              <Item>
                <Controller
                  control={control}
                  name="pricePerUnit"
                  render={({ field: { onChange, name, value } }) => (
                    <NumberFormat
                      customInput={TextField}
                      size="small"
                      autoFocus
                      label="Price per unit"
                      variant="outlined"
                      decimalScale={2}
                      prefix={"£"}
                      isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === "" || floatValue! <= 10000;
                      }}
                      allowNegative={false}
                      fullWidth
                      name={name}
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors.pricePerUnit)}
                      helperText={
                        Boolean(errors.pricePerUnit)
                          ? errors.pricePerUnit?.message
                          : ""
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Item>
            </Grid>

            <Grid item md={6} xs={12}>
              <Item>
                <TextField
                  {...register("manufacturer")}
                  label="Manufacturer"
                  size="small"
                  spellCheck
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Item>
            </Grid>

            {/* <Grid item md={6} xs={12}></Grid> */}

            <Grid item md={6} xs={12}>
              <Item>
                <TextField
                  {...register("manufacturerId")}
                  label="Manufacturer ID"
                  size="small"
                  spellCheck
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Item>
            </Grid>

            <Grid item md={6} xs={12}>
              <Item>
                <TextField
                  value={item?.note || ""}
                  label="Customer Note"
                  size="small"
                  spellCheck
                  fullWidth
                  multiline
                  rows={4}
                  aria-readonly
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    style: {
                      fontSize: ".85rem",
                    },
                  }}
                />
              </Item>
            </Grid>

            <Grid item md={6} xs={12}>
              <Item>
                <TextField
                  {...register("vendorNote")}
                  size="small"
                  label="Vendor note"
                  InputLabelProps={{ shrink: true }}
                  spellCheck
                  fullWidth
                  multiline
                  rows={4}
                  placeholder="Leave your reply here..."
                  inputProps={{ style: { fontSize: ".85rem" } }}
                />
              </Item>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outlined" type="submit">
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateProductToRfqForm;
