import DemoLogo from "assets/logo/dental_assist_logo_white_BG_horizontal.png";
import useFetchTenantDetails from "modules/User/hooks/useFetchTenantDetails";

const BrandLogo = () => {
  const { currentTenant, loading } = useFetchTenantDetails();

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      padding: "16px", // Add padding to give some space around the logo
      boxSizing: "border-box" as "border-box", // Ensures padding is included in the width
    },
    logo: {
      maxWidth: "100%", // Ensures the logo scales down with the container
      maxHeight: "60px", // Ensures the logo doesn't exceed 60px in height
      height: "auto", // Maintains the aspect ratio of the logo
      objectFit: "contain" as "contain", // Ensures the image does not become distorted
    },
  };

  return (
    <div style={styles.container}>
      {currentTenant.logoUrl && (
        <img
          src={currentTenant.logoUrl}
          alt="Company logo"
          style={styles.logo}
        />
      )}
      {!currentTenant.logoUrl && !loading && (
        <img src={DemoLogo} alt="Company logo" style={styles.logo} />
      )}
    </div>
  );
};

export default BrandLogo;
