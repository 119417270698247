import { Box, SwipeableDrawer } from "@mui/material";

import React from "react";
import { useSwipeableDrawer } from "context/SwipeableDrawerContext/SwipeableDrawerContext";

const GlobalSwipeableDrawer: React.FC = () => {
  const { isDrawerOpen, drawerContent, closeDrawer } = useSwipeableDrawer();

  return (
    <SwipeableDrawer
      anchor="right"
      open={isDrawerOpen}
      onClose={closeDrawer}
      onOpen={() => {}}
    >
      <Box
        sx={{
          width: "auto", // Set to the desired width of the drawer
        }}
      >
        {drawerContent}
      </Box>
    </SwipeableDrawer>
  );
};

export default GlobalSwipeableDrawer;
