import { useCreateRfqMutation } from "interfaces/graphql";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "modules/Stock/context/SnackbarContext";

export const useCreateRfq = () => {
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const [createDraftRFQ, { data, loading, error }] = useCreateRfqMutation();

  useEffect(() => {
    if (data) {
      navigate(`/rfqs/${data.createRfq.id}`);
      showMessage("Draft RFQ created successfully", "success");
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      showMessage(
        "We apologize for the inconvenience. Please try again later or contact our support team for further assistance.",
        "error"
      );
    }
  }, [error]);

  const onCreateClick = () => {
    createDraftRFQ();
  };

  return { onCreateClick, loading };
};
