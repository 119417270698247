import { DialogContent, DialogTitle } from "@mui/material";

import { IVendorRfqTableData } from "modules/VendorRfqResponse/interfaces";
import UpdateProductToRfqForm from "modules/VendorRfqResponse/components/UpdateProductToRfqForm/UpdateProductToRfqForm";

interface IUpdateVendorQuoteRFQItemDialogProps {
  item: IVendorRfqTableData;
}

const UpdateVendorQuoteRFQItemDialog = ({
  item,
}: IUpdateVendorQuoteRFQItemDialogProps) => {
  return (
    <>
      <DialogTitle>Edit Product</DialogTitle>
      <DialogContent>
        <UpdateProductToRfqForm item={item} />
      </DialogContent>
    </>
  );
};

export default UpdateVendorQuoteRFQItemDialog;
