import { Box, CircularProgress, useTheme } from "@mui/material";

const Loader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:
          theme.palette.mode === "dark"
            ? "rgba(0, 0, 0, 0.7)"
            : "rgba(255, 255, 255, 0.8)", // Adjust overlay color based on theme
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;
