import { AllStockProvider } from "modules/Stock/context/AllStockContext";
import { Box } from "@mui/material";
import StockSearch from "components/StockSearch/StockSearch";

const StockToolbar = () => {
  return (
    <AllStockProvider>
      <Box
        sx={{
          width: "100%",
          border: "1px solid #e0e0e0",
        }}
      >
        <StockSearch />
      </Box>
    </AllStockProvider>
  );
};

export default StockToolbar;
