import gql from "graphql-tag";

export const GET_VENDORS = gql`
  query vendors($filter: VendorFilter) {
    vendors(filter: $filter) {
      contactEmail
      contactName
      hidden
      id
      name
      note
      website
    }
  }
`;

export const GET_VENDORS_FOR_RFQ = gql`
  query vendorsForRfq($filter: VendorFilter) {
    vendors(filter: $filter) {
      contactEmail
      contactName
      hidden
      id
      name
      note
      website
    }
  }
`;

export const VENDOR_REPLIED_SUBSCRIPTION = gql`
  subscription vendorReplied {
    vendorReplied {
      id
      rfqId
    }
  }
`;
