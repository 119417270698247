import {
  Autocomplete,
  Box,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { IFolder, IItemSummary } from "interfaces/graphql";
import { useMemo, useState } from "react";

import Fuse from "fuse.js";
import SearchIcon from "@mui/icons-material/Search";
import folderIcon from "assets/illustrations/folder_transparent.png";
import itemIcon from "assets/illustrations/file.jpg";
import useAllStock from "modules/Stock/hooks/useAllStock";
import { useNavigate } from "react-router-dom";

const StockSearch = () => {
  const navigate = useNavigate();
  const { stock } = useAllStock();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<
    (IFolder | IItemSummary | any)[]
  >([]);

  const combinedData = useMemo(() => {
    if (
      !stock ||
      !Array.isArray(stock.folders) ||
      !Array.isArray(stock.items)
    ) {
      return [];
    }
    return [
      ...stock.folders.map((folder: IFolder) => ({
        ...folder,
        type: "Folder",
      })),
      ...stock.items.map((item: IItemSummary) => ({
        ...item,
        type: "ItemSummary",
      })),
    ];
  }, [stock?.folders, stock?.items]);

  const fuseOptions = {
    includeScore: true,
    threshold: 0.4,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["name"],
    shouldSort: true,
    findAllMatches: false,
    useExtendedSearch: true,
    tokenize: true,
    matchAllTokens: false,
  };

  const fuse = new Fuse(combinedData, fuseOptions);

  const handleInputSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length > 0) {
      const results = fuse.search(value);
      const filteredOptions = results.map((result) => result.item);

      setFilteredOptions(filteredOptions);
    } else {
      setFilteredOptions(combinedData);
    }
  };

  const handleSelect = (
    _event: React.SyntheticEvent,
    value: IFolder | IItemSummary
  ) => {
    if (value) {
      if (value.__typename === "Folder") {
        navigate(`/stock/${value.name}/${value.id}`);
      } else if (value.__typename === "ItemSummary") {
        navigate(`/stock/item/${value.id}`);
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();

      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }

      navigate(`/stock/search/${searchTerm}`);
    }
  };

  const displayGroupName = (type: string) => {
    if (type === "Folder") {
      return "Folder";
    } else if (type === "ItemSummary") {
      return "Item";
    }
    return "";
  };

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "2px 4px",
        }}
      >
        <IconButton sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <Autocomplete
          freeSolo
          onChange={handleSelect}
          groupBy={(option) => `${displayGroupName(option.type)}s`}
          sx={{
            width: "100%",
            paddingRight: "1rem",
          }}
          options={filteredOptions}
          getOptionLabel={(option) => option.name}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) =>
              option.name.toLowerCase().includes(inputValue.toLowerCase())
            )
          }
          renderInput={(params) => (
            <InputBase
              {...params.InputProps}
              inputProps={{
                ...params.inputProps,
                style: { width: "calc(100% - 48px)" },
              }}
              sx={{ ml: 1, flex: 1, width: "100%" }}
              placeholder="Search"
              value={searchTerm}
              onKeyDown={handleKeyDown}
              onChange={handleInputSearchChange}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  option.imageUrl
                    ? option.imageUrl
                    : option.type === "Folder"
                    ? folderIcon
                    : itemIcon
                }
                alt={option.name}
                style={{ width: "56px", height: "56px", marginRight: "8px" }}
              />
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  {option.name}
                </Typography>
                {option.type === "ItemSummary" && (
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Manufacturer: {option.manufacturer || "Unknown"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {option.isLowStock ? "Low Stock" : "In Stock"} | Price:{" "}
                      {option.pricePerUnit
                        ? `£${option.pricePerUnit.toFixed(2)}`
                        : "N/A"}
                    </Typography>
                  </>
                )}
                {/* [BUG]: server needs to return children prop from Folder */}
                {/* {option.type === "Folder" && (
                  <Typography variant="body2" color="textSecondary">
                    Contains {option?.children?.length || "0"} items
                  </Typography>
                )} */}
              </Box>
            </li>
          )}
        />
      </Paper>
    </>
  );
};

export default StockSearch;
