import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { useCloneRfqMutation } from "interfaces/graphql";
import { useDialog } from "context/DialogContext/DialogContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "modules/Stock/context/SnackbarContext";

interface ICloneRFQDialogProps {
  id: string;
}

const CloneRFQDialog = ({ id }: ICloneRFQDialogProps) => {
  const { showMessage } = useSnackbar();
  const { closeDialog } = useDialog();
  const navigate = useNavigate();

  const [cloneRfq, { data, loading, error }] = useCloneRfqMutation();

  useEffect(() => {
    if (data) {
      showMessage("RFQ cloned successfully!", "success");
      navigate(`/rfqs/${data.cloneRfq.id}`);
      closeDialog();
    }
  }, [data, navigate]);

  const onCloneClick = () => {
    if (id) {
      cloneRfq({
        variables: {
          id,
        },
      });
    }
  };

  useEffect(() => {
    if (error) {
      showMessage("Error cloning RFQ", "error");
    }
  }, [error]);

  const onDialogClose = () => closeDialog();

  return (
    <>
      <DialogTitle>Clone RFQ</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to clone this RFQ?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onDialogClose}
          color="secondary"
          variant="outlined"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={onCloneClick}
          color="primary"
          variant="outlined"
          disabled={loading}
        >
          Clone
        </Button>
      </DialogActions>
    </>
  );
};

export default CloneRFQDialog;
