export function displayPathName(name: string): string {
  const pathNames: Record<string, string> = {
    "/": "Dashboard",
    "/dashboard": "Dashboard",
    "/orders": "Purchase Orders",
    "/rfqs": "RFQs",
    "/rfqs/": "RFQ",
    "/stock": "Stock",
    "/mobile/stock-search": "Stock Search",
    "/low-stock": "Low Stock",
    "/vendors": "Vendors",
    "/app-settings": "Settings",
    "/user": "User",
  };

  if (name.startsWith("/mobile/add-rfq-item")) {
    return "Add RFQ Item";
  }

  if (name.startsWith("/orders/")) {
    return "Order";
  }

  if (name in pathNames) {
    return pathNames[name];
  }

  const parts = name.split("/");
  if (parts[1] === "rfqs" && parts[2] !== undefined) {
    return "RFQ";
  }

  return "";
}
