import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";

import LogoutButton from "components/Logout/Logout";
import LogoutIcon from "@mui/icons-material/Logout";
import { useState } from "react";

interface INavSignOutProps {
  open: boolean;
}
const NavSignOut = ({ open }: INavSignOutProps) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Tooltip title={open ? "" : "Sign Out"} placement="right" arrow>
        <ListItemButton key={"signOut"} onClick={() => setOpenDialog(true)}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Sign Out"} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </Tooltip>
      {openDialog && (
        <Dialog
          open={open}
          aria-describedby="alert dialog to confirm sign out"
          keepMounted
          onClose={() => setOpenDialog(false)}
        >
          <DialogTitle>Sign Out</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: "1rem",
                mb: "1rem",
              }}
            >
              <Typography variant="body1" sx={{ mb: "1rem" }}>
                Are you sure you want to sign out?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ mb: ".5rem" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenDialog(false)}
            >
              Cancel
            </Button>
            <LogoutButton />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default NavSignOut;
