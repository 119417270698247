import React, { ReactNode, createContext, useContext, useState } from "react";

import { DialogProps } from "@mui/material";

interface DialogContextType {
  isOpen: boolean;
  dialogContent: ReactNode;
  dialogProps: Omit<DialogProps, "open">;
  openDialog: (content: ReactNode, props?: Omit<DialogProps, "open">) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};

interface DialogProviderProps {
  children: ReactNode;
}

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const [dialogContent, setDialogContent] = useState<ReactNode>(null);
  const [dialogProps, setDialogProps] = useState<Omit<DialogProps, "open">>({});
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = (
    content: ReactNode,
    props: Omit<DialogProps, "open"> = {}
  ) => {
    setDialogContent(content);
    setDialogProps(props);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setDialogContent(null);
    setDialogProps({});
  };

  return (
    <DialogContext.Provider
      value={{ isOpen, openDialog, closeDialog, dialogProps, dialogContent }}
    >
      {children}
    </DialogContext.Provider>
  );
};
