import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import { FormEvent, useEffect, useRef, useState } from "react";

import CreateFolderDialog from "../CreateFolderDialog/CreateFolderDialog";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useParams } from "react-router-dom";

const MobileStockAppBarVerticalMenu = () => {
  const { id } = useParams<{ id: string; name: string }>();
  const [open, setOpen] = useState(false);
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = (e: FormEvent) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCreateFolder = () => {
    setOpenCreateFolder(true);
    setOpen(false);
  };

  const handleCreateFolderClose = () => {
    setOpenCreateFolder(false);
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <div>
          <IconButton
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => handleToggle(e)}
          >
            <MoreVertIcon />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            style={{ zIndex: 99 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                    >
                      <MenuItem onClick={handleCreateFolder}>
                        <ListItemIcon>
                          <CreateNewFolderIcon fontSize="small" />
                        </ListItemIcon>
                        Add folder
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
      {openCreateFolder && (
        <CreateFolderDialog
          open={openCreateFolder}
          onClose={handleCreateFolderClose}
          parentId={id}
        />
      )}
    </>
  );
};

export default MobileStockAppBarVerticalMenu;
