import React, { ReactNode, createContext, useEffect, useState } from "react";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

// Define the type for the context value
type InstallPromptContextType = BeforeInstallPromptEvent | null;

// Create a context with the specified type
const InstallPromptContext = createContext<InstallPromptContextType>(null);

interface InstallPromptProviderProps {
  children: ReactNode; // This type is for any valid react child (component, element, etc.)
}
const InstallPromptProvider: React.FC<InstallPromptProviderProps> = ({
  children,
}) => {
  const [installPrompt, setInstallPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const beforeInstallPromptHandler = (e: Event) => {
      e.preventDefault();
      setInstallPrompt(e as BeforeInstallPromptEvent);
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler
      );
    };
  }, []);

  return (
    <InstallPromptContext.Provider value={installPrompt}>
      {children}
    </InstallPromptContext.Provider>
  );
};

export { InstallPromptContext, InstallPromptProvider };
