import { Drawer, DrawerHeader } from "./DrawerStyles";

import BrandLogo from "components/BrandLogo/BrandLogo";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import NavList from "components/NavList/NavList";
import { useTheme } from "@mui/material/styles";

interface IDesktopDrawerProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DesktopDrawer = ({ open, setOpen }: IDesktopDrawerProps) => {
  const theme = useTheme();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BrandLogo />

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>

      <NavList open={open} />
    </Drawer>
  );
};

export default DesktopDrawer;
