import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { IRfqResponsePayload } from "modules/VendorRfqResponse/interfaces";
import { useDialog } from "context/DialogContext/DialogContext";

interface IConfirmVendorRFQResponseDialogProps {
  onConfirmClick: () => void;
  payload: IRfqResponsePayload;
}

const ConfirmVendorRFQResponseDialog = ({
  onConfirmClick,
  payload,
}: IConfirmVendorRFQResponseDialogProps) => {
  const { closeDialog } = useDialog();

  const onClose = () => {
    closeDialog();
  };

  const atLeastOnePriceMissing = payload.items.some(
    (item) => item.pricePerUnit === undefined
  );

  return (
    <>
      <DialogTitle>Confirm Vendor RFQ Response</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to send this RFQ response?
        </DialogContentText>
        <br />
        {atLeastOnePriceMissing && (
          <Alert severity="error">
            Some items do not have a price. If this is correct - send the RFQ,
            otherwise "cancel" this dialog and fill in the missing prices.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button onClick={onConfirmClick} variant="outlined">
          Send
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmVendorRFQResponseDialog;
