import { UserContext } from "modules/User/context/UserContext";
import { useContext } from "react";

const useUserDetails = () => {
  const userDetails = useContext(UserContext);
  if (!userDetails) {
    throw new Error("useUserDetails must be used within UserProvider");
  }
  return userDetails;
};

export default useUserDetails;
