import { Draft, produce } from "immer";

import { IVendorRfqItem } from "../interfaces";

export interface IVendorRfqResponseState {
  quoteId: string;
  userNote?: string;
  userItems: IVendorRfqItem[];
  vendorNote?: string;
  vendorItems: IVendorRfqItem[];
  selectedItem: IVendorRfqItem | null;
  loading: boolean;
  error: unknown;
}

export enum VendorRfqResponseActions {
  setUserData = "SET_USER_DATA",
  setVendorItems = "SET_VENDOR_ITEMS",
  setUserItems = "SET_USER_ITEMS",
  setVendorNote = "SET_VENDOR_NOTE",
  setLoading = "SET_LOADING",
  setError = "SET_ERROR",
}

export type IVendorRfqResponseAction =
  | {
      type: VendorRfqResponseActions.setUserData;
      payload: {
        quoteId: string;
        userItems: IVendorRfqItem[];
        userNote: string;
      };
    }
  | {
      type: VendorRfqResponseActions.setVendorItems;
      payload: IVendorRfqItem[];
    }
  | {
      type: VendorRfqResponseActions.setUserItems;
      // TODO: map into one interface and use throughout
      payload: IVendorRfqItem[];
    }
  | {
      type: VendorRfqResponseActions.setLoading;
      payload: boolean;
    }
  | {
      type: VendorRfqResponseActions.setError;
      payload: any;
    }
  | {
      type: VendorRfqResponseActions.setVendorNote;
      payload: string;
    };

type ActionHandlers = {
  [key in VendorRfqResponseActions]: (
    draft: Draft<IVendorRfqResponseState>,
    action: IVendorRfqResponseAction
  ) => void;
};

const handleSetUserData = (
  draft: Draft<IVendorRfqResponseState>,
  action: IVendorRfqResponseAction
) => {
  const { rfqId, userItems } = action.payload;
  draft.quoteId = action.payload.quoteId;
  draft.userItems = userItems;
  draft.userNote = action.payload.userNote;
};

const handleSetVendorItems = (
  draft: Draft<IVendorRfqResponseState>,
  action: IVendorRfqResponseAction
) => {
  draft.vendorItems = action.payload;
};

const handleSetUserItems = (
  draft: Draft<IVendorRfqResponseState>,
  action: IVendorRfqResponseAction
) => {
  draft.userItems = action.payload;
};

const handleSetVendorNote = (
  draft: Draft<IVendorRfqResponseState>,
  action: IVendorRfqResponseAction
) => {
  draft.vendorNote = action.payload;
};

const handleSetLoading = (
  draft: Draft<IVendorRfqResponseState>,
  action: IVendorRfqResponseAction
) => {
  if (typeof action.payload !== "boolean") {
    throw new Error("Invalid payload type for SET_LOADING");
  }
  draft.loading = action.payload;
};

const handleSetError = (
  draft: Draft<IVendorRfqResponseState>,
  action: IVendorRfqResponseAction
) => {
  draft.error = action.payload as any;
  draft.loading = false;
};

const actionHandlers: ActionHandlers = {
  [VendorRfqResponseActions.setUserData]: handleSetUserData,
  [VendorRfqResponseActions.setVendorItems]: handleSetVendorItems,
  [VendorRfqResponseActions.setUserItems]: handleSetUserItems,
  [VendorRfqResponseActions.setVendorNote]: handleSetVendorNote,
  [VendorRfqResponseActions.setLoading]: handleSetLoading,
  [VendorRfqResponseActions.setError]: handleSetError,
};

const vendorRfqResponseReducer = produce(
  (draft: Draft<IVendorRfqResponseState>, action: IVendorRfqResponseAction) => {
    const handler = actionHandlers[action.type as keyof ActionHandlers];

    if (handler) {
      handler(draft, action);
    } else {
      throw new Error("Action type is not defined");
    }
  }
);

export default vendorRfqResponseReducer;
