import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import { FormEvent, useEffect, useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useCreateRfq } from "modules/RFQ/hooks/useCreateRFQ";

const MobileRFQsAppBarVerticalMenu = () => {
  const { onCreateClick, loading } = useCreateRfq();

  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleMenuClick = () => {
    setConfirmationOpen(true);
  };

  const handleConfirmationAccept = () => {
    onCreateClick();
    setConfirmationOpen(false);
  };

  const handleConfirmationReject = () => {
    setConfirmationOpen(false);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = (e: FormEvent) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <div>
          <IconButton
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => handleToggle(e)}
          >
            <MoreVertIcon />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            style={{ zIndex: 99 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                    >
                      <MenuItem onClick={handleMenuClick}>
                        <ListItemIcon>
                          <AddIcon />
                        </ListItemIcon>
                        Create new
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
      <Dialog
        open={confirmationOpen}
        onClose={handleConfirmationReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Create new RFQ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are going to create new RFQ and redirected to new page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmationReject}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmationAccept}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={loading}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MobileRFQsAppBarVerticalMenu;
