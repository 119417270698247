import * as Yup from "yup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import CreateImage from "../CreateImage/CreateImage";
import { Item } from "components/Form/ItemStyles/Item";
import { NAVIGATE_STOCK } from "modules/Stock/grahpql/queries";
import { useCreateFolderMutation } from "interfaces/graphql";
import { useForm } from "react-hook-form";
import { useSnackbar } from "modules/Stock/context/SnackbarContext";
import { yupResolver } from "@hookform/resolvers/yup";

interface ICreateFolderDialogProps {
  open: boolean;
  onClose: () => void;
  parentId?: string;
}
interface FormValues {
  name: string;
  notes: string;
  photo: File;
}

const CreateFolderDialog = ({
  open,
  onClose,
  parentId,
}: ICreateFolderDialogProps) => {
  const { showMessage } = useSnackbar();

  const [fileDataURL, setFileDataURL] = useState<string | null>(null);
  const [createFolderMutation, { data, loading, error }] =
    useCreateFolderMutation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().max(200),
    notes: Yup.string().max(1000),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: FormValues) => {
    const { name } = data;
    createFolderMutation({
      variables: {
        input: {
          name,
          imagePayload: fileDataURL,
          parentId,
        },
      },
      refetchQueries: [{ query: NAVIGATE_STOCK, variables: { parentId } }],
    });
  };

  useEffect(() => {
    if (data) {
      onClose();
      reset();

      showMessage("Folder created successfully", "success");
    }
  }, [data, reset, onClose]);

  if (error) {
    showMessage("There was a problem creating the folder", "error");
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="create-folder"
        maxWidth="xs"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Create folder</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Item>
                  <CreateImage isFolder setFileDataURL={setFileDataURL} />
                </Item>
              </Grid>

              <Grid item xs={12}>
                <Item>
                  <TextField
                    {...register("name")}
                    size="small"
                    label="Folder name"
                    defaultValue=""
                    spellCheck
                    autoFocus
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.name)}
                  />
                </Item>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ mb: ".5rem" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              disabled={loading}
            >
              Close
            </Button>
            <Button variant="outlined" type="submit" disabled={loading}>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateFolderDialog;
