import {
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { ALERTS_SUBSCRIPTION } from "modules/Stock/grahpql/queries";
import NotificationBell from "components/NotificationBell/NotificationBell";
import { VENDOR_REPLIED_SUBSCRIPTION } from "modules/Vendors/graphql/queries";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "@apollo/client";

type INotificationType = "vendor" | "stock";

interface INotification {
  message: string;
  itemId: string;
  type: INotificationType;
}

const Notifications = () => {
  const { data: vendorAlert } = useSubscription(VENDOR_REPLIED_SUBSCRIPTION);
  const { data: stockAlert } = useSubscription(ALERTS_SUBSCRIPTION);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    if (vendorAlert && vendorAlert.vendorReplied) {
      const newNotification: INotification = {
        message: `Vendor replied to #${vendorAlert.vendorReplied.rfqId}`,
        itemId: vendorAlert.vendorReplied.rfqId,
        type: "vendor",
      };
      setNotifications((prev) => [...prev, newNotification]);
    }
  }, [vendorAlert]);

  useEffect(() => {
    if (stockAlert && stockAlert.itemAlert) {
      const newNotification: INotification = {
        message: `Stock item "${stockAlert.itemAlert.name}" dropped below minimum level`,
        itemId: stockAlert.itemAlert.id,
        type: "stock",
      };
      setNotifications((prev) => [...prev, newNotification]);
    }
  }, [stockAlert]);

  const clearNotifications = () => {
    setNotifications([]);
  };

  const handleNotificationClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationMessageClick = (notification: INotification) => {
    const { itemId, type } = notification;

    if (type === "vendor") {
      navigate(`/rfqs/${itemId}/received`);
    } else if (type === "stock") {
      navigate(`/stock/item/${itemId}`);
    }

    setAnchorEl(null);
    clearNotifications();
  };

  const openNotifications = Boolean(anchorEl);
  const id = openNotifications ? "simple-popover" : undefined;
  return (
    <>
      <NotificationBell
        count={notifications.length}
        handleNotificationClick={handleNotificationClick}
      />
      <Popover
        id={id}
        open={openNotifications}
        anchorEl={anchorEl}
        onClose={handleNotificationClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ boxShadow: 3 }}
      >
        <List>
          {notifications.length === 0 ? (
            <ListItem>
              <ListItemText primary="No notifications" />
            </ListItem>
          ) : (
            notifications.map((notification, index) => (
              <ListItem
                key={index}
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
              >
                <ListItemText>
                  <Typography
                    onClick={() => handleNotificationMessageClick(notification)}
                    style={{
                      color: "#3f51b5",
                      textDecoration: "none",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onMouseOver={({ currentTarget }) =>
                      (currentTarget.style.textDecoration = "underline")
                    }
                    onMouseOut={({ currentTarget }) =>
                      (currentTarget.style.textDecoration = "none")
                    }
                  >
                    {notification.message}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))
          )}
        </List>
      </Popover>
    </>
  );
};

export default Notifications;
