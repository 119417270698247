import {
  Box,
  Table as MUITable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import {
  Row,
  TableInstance,
  useExpanded,
  useRowSelect,
  useTable,
} from "react-table";

import { ITableProps } from "./interfaces";
import TableCheckbox from "./actions/TableCheckbox";

const Table = ({
  columns: userColumns,
  data,
  hiddenColumns = [],
  subTable = false,
  selection,
  highlight,
  highlightRow,
  renderRowSubComponent,
  selectRow,
  hover = true,
  size = "medium",
  onRowClick = () => {},
}: ITableProps) => {
  const columns = useMemo(() => {
    // Define the selection column
    const selectionColumn = selection?.manualSelection
      ? [
          {
            id: "selection",
            Header: ({ getToggleAllPageRowsSelectedProps }: TableInstance) => (
              <div>
                <TableCheckbox {...getToggleAllPageRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }: { row: Row }) => (
              <div>
                <TableCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
        ]
      : [];

    return [...selectionColumn, ...userColumns];
  }, [userColumns, selection?.manualSelection]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: { selectedRowIds },
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["ID", ...hiddenColumns],
        ...(selection?.initialSelections && {
          selectedRowIds: selection.initialSelections,
        }),
        ...(highlight && { selectedRowIds: 1 }),
      },
      getRowId: (row: Row) => row.id,
    },
    useExpanded,
    useRowSelect
  );

  const memoizedSelectedRowIds = useMemo(
    () => Object.keys(selectedRowIds),
    [selectedRowIds]
  );

  useEffect(() => {
    selectRow && selectRow(memoizedSelectedRowIds);
  }, [selectRow, memoizedSelectedRowIds]);

  return (
    <>
      <TableContainer component={subTable ? Box : Paper}>
        <MUITable {...getTableProps()} size={size}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <React.Fragment key={index}>
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </TableCell>
                  ))}
                </TableRow>
              </React.Fragment>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <React.Fragment key={rowProps.key}>
                  <TableRow
                    {...rowProps}
                    hover={hover}
                    sx={{ cursor: onRowClick ? "pointer" : "default" }}
                    onClick={() => onRowClick(row.original)}
                  >
                    {row.cells.map((cell) => (
                      <TableCell
                        {...cell.getCellProps({
                          className: cell.column.className,
                          style: cell.column.style,
                        })}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.isExpanded && (
                    <TableRow>
                      <TableCell colSpan={visibleColumns.length}>
                        {renderRowSubComponent &&
                          renderRowSubComponent({ row })}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </MUITable>
      </TableContainer>
    </>
  );
};

export default Table;
